.links-panel {
    padding: 16px;
    
    overflow-y: auto;
    box-shadow: inset -2px 0 5px rgba(0, 0, 0, 0.1);

  }
  
  .links-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .links-panel-header .ant-btn-text {
    color: #000; /* Optional: style the close button */
  }
  
  .links-panel-content {
    margin-top: 16px;
  }
  
  .links-panel-content p {
    margin-bottom: 12px; /* Optional: adjust spacing between items */
  }
  
  .links-panel-content a {
    display: block;
    color: #1890ff;
    text-decoration: none;
    margin-bottom: 8px;
  }
  
  .links-panel-content a:hover {
    text-decoration: underline;
  }
  
  .droppable-border {
    border: 2px dashed #1890ff;
    border-color: var(--background-accent-light);
    border-radius: 4px;
    padding: 16px;
    margin-top: 16px;
    text-align: center;
  }