.custom-spin.ant-spin .ant-spin-dot {
    font-size: 16px; /* Adjust the size of the entire spinner */
    margin-left: 4px !important;
    margin-bottom: 16px !important;
  }
  
  .custom-spin .ant-spin-dot-item {
    width: 8px;
    height: 8px;
    background-color: var(--background-accent-medium); /* Set your desired color here */
    
  }
  .custom-spin-text
  {
    color: var(--title-text-light);
    font-size: 8px;
    margin-left: 16px;
    margin-bottom: 0px;
    margin-top:0px;
    margin-right:0px;
    padding: 0px;
  }