.realm-object-title {
    transition: filter 0.3s ease, opacity 0.3s ease;
  }
  
  .realm-object-title.in-action {
    filter: blur(3px); /* Apply blur when in action */
    opacity: 0.7; /* Optional: Reduce opacity to emphasize the effect */
  }
  
.ant-dropdown-menu {
    background-color: var(--background-light) !important;
    color: var(--title-text-dark) !important;
    border: 2px solid var(--background-dark);
  }