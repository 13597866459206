.question-card-item {
  background-color: var(--background-medium);
  border-radius: 4px;
  border-color: var(--background-dark);
  padding: 0px !important;
  margin: 0px 4px 2px 4px !important; /* Add spacing between list items */
  position: relative; /* For positioning the icon */
}
.question-card-header {
  color: var(--body-text-light) !important;
  display: flex;
  align-items: center;
  margin: 0px !important;
}

.ant-card-head {
  padding: 0px 8px 0px  !important;
  margin: 0px !important;
  min-height: 0px !important;
}

.question-card-item .ant-card-body {
  padding: 4px !important;
}

.question-card-content {
  display: flex;
  flex-direction: column;
}

.question-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  margin-left: 4px;
}

.question-card-label {
  font-size: var(--body-text-size-medium);
  color: var(--body-text-light);
}

.question-card-icon {
  color: var(--body-text-light);
}

.ant-input.custom-input {
  font-size: var(--body-text-size-medium) !important;
  background-color: var(--background-light) !important;
  border: 1px solid var(--background-medium) !important; /* Corrected border property */
  color: var(--body-text-dark) !important;
  width: 99%; /* Changed to 100% for consistency */
  margin: .5%;
}

.ant-input.custom-input::placeholder,
.ant-input-password.custom-input::placeholder {
  color: var(--body-text-medium);
  text-align: left;
}

.ant-input.custom-input:focus,
.ant-input.custom-input:hover {
  background-color: var(--background-very-light-2) !important;
  border: 2px solid var(--background-dark) !important; /* Corrected border property */
  color: var(--body-text-medium);
}

.question-card-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-card-avatar img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
