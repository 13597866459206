.fab-realm-object-label{
  color: var(--title-text-light);
  padding: 4px;
}

.fab-realm-object-description{
  color: var(--title-text-light);
  padding: 4px;
  margin-left: 8px;
}

.fab-realm-object-container{
  gap: 10px;
}

.custom-tabs .ant-tabs-nav::before {
  border-bottom: none !important;
}

.tabs-background {
  background-color: var(--background-medium-dark);
  padding: 4px 4px 16px 4px;
  border-radius: 8px;
  align-items: top !important;
  align-self: top !important;
}


.ant-tabs-nav-wrap {
  border-bottom: 0px solid var(--border-color-medium);
}

.ant-tabs-card .ant-tabs-tab {
  background-color: var(--background-medium-dark) !important; /* Background color */
  border: 2px solid transparent !important; /* Tab border color */
  padding: 0px 0px !important; /* Padding */
}

.ant-tabs-tab {
  background-color: var(--background-medium-dark) !important; /* cardBg */
  padding: 8px 16px !important; /* cardPadding */
  color: var(--title-text-light) !important; /* itemColor */
}

.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 24px !important; /* titleFontSize */
}

.public-highlight {
  border: 3px solid var(--background-accent-dark) !important;
  border-radius: 8px !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--title-text-glowmode) !important; /* itemActiveColor */
  border-bottom: 2px solid var(--background-accent-light) !important; /* inkBarColor */
}

.ant-tabs-tab .ant-tabs-tab-btn {
  border-bottom: 2px solid var(--background-medium);
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: var(--title-text-glowmode) !important; /* itemHoverColor */
  border-bottom: 2px solid var(--background-accent-dark) !important; /* Hover underline color */
}

.floating-panel {
  z-index: 100;
}

.realm-object-button-bar {
  background-color: var(--background-medium-dark) !important;
  border: 2px solid var(--background-medium-light) !important;
  padding: 8px !important;
  border-radius: 8px !important;
}

/* Custom styles for the Ant Design Tooltip */
.ant-tooltip {
  background-color: var(--background-dark) !important;
  color: var(--body-text-accent) !important;
}

/* Ensure the arrow of the tooltip is also styled */
.ant-tooltip-arrow {
  border-color: var(--background-dark) !important;
}

/* Ensure the inner content of the tooltip is also styled */
.ant-tooltip-inner {
  background-color: var(--background-dark) !important;
  color: var(--body-text-accent) !important;
}

.custom-tab-background {
  background-color: var(--background-medium) !important;
  border-radius: 8px 8px 0 0;
  border-color: var(--background-accent-medium);
  padding: 8px;
  border-bottom: 1px solid var(--background-accent-dark);
}

/* New styles added for responsive layout */
.header-container {
  margin-bottom: 4px;
}

.tabs-container {
      flex: 1;
      order: 1;
      margin-right: 16px;
  }

@media (max-width: 1199px) { /* lg and smaller screens */
  .fab-fill-parent {
      display: flex;
      flex-direction: column;
  }

  .action-bar-container {
      margin-bottom: 16px;
  }
}

.ant-col.no-margin{
  margin: 0px !important;
}
.ant-row.no-gap{
  gap: 0px !important;
}

.ant-col.stick-right {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 1200px) {
  .action-bar {
    position: relative;
    top: 10px;
    height: 0px;
    z-index: 100;
  }
}

@media (min-width: 800px) {
  .action-bar-sm{
    position: relative;
    top: 30px;
    height: 0px;
    z-index: 100;
  }
}

.realm-object-header
{
  margin-bottom: 8px; 
}

.floating-links-button {
  position: absolute;
  right: 8px;
  top: 80px;
  z-index: 100;
}

.close-button-container {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 101; /* Ensure it appears above other content */
}

.realm-object-links-panel-content {
  padding-top: 40px; /* Adjust padding to avoid overlap with close button */
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  
}

.realm-object-links-sider{
  border-radius: 8px; /* Adjust this value to your desired roundness */
  background-color: inherit; /* Use 'inherit' or set the same background color as your parent */
  overflow: hidden; /* Ensures content within the Sider stays within the rounded corners */
  border: none; /* Remove any potential border that might cause a white edge */
}

.ant-layout {
  background-color: var(--background-medium);
}

/* Container for IconifiedTitle and spinner */
.title-spinner-container {
  display: flex;
  align-items: center; /* Vertically center items */
}

.custom-spin.ant-spin .ant-spin-dot {
  font-size: 24px; /* Adjust the size of the entire spinner */
}

.custom-spin .ant-spin-dot-item {
  width: 12px;
  height: 12px;
  background-color: var(--background-accent-medium); /* Set your desired color here */
}

/* Optional spacing between title and spinner */
.title-spinner-container .custom-spin {
  margin-left: 8px; /* Add some space between title and spinner */
}
