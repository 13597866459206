.user-status-container {
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center; /* Center align items horizontally */
    margin-right: 16px; /* Adds 16px margin to the right */
}

.user-status-avatar {
    background-color: var(--background-accent-dark);
    color: var(--title-text-light);
    border: 1px solid var(--background-accent-medium) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: var(--body-text-size-medium);
    font-family: var(--main-font);
    transition: all 0.3s ease;
}

.user-status-avatar:hover {
    background-color: var(--background-accent-medium);
    color: var(--title-text-dark);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-color: var(--button-primary-border-hover-color);
    transform: scale(1.05);
}

.user-status-text {
    margin-top: 8px; /* Space between the avatar and text */
    font-size: var(--body-text-size-small);
    color: var(--title-text-light);
    text-align: center;
}
