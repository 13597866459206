.ant-select   {
    width: 100% !important;
    border-radius: 8px !important;
    /* Background and text color inside the input field */
    padding: 4px 8px !important;
    font-size: 16px !important;
    border: none; /* Remove default border */
    outline: none; /* Remove default outline on focus */
    min-width: 200px !important; 
  }
  
  .ant-select-item {
    font-size: 18px !important;
    color: var(--title-text-dark) !important;
  }
  
  .ant-select-dropdown {
    background-color: var(--background-light) !important; 
    border: none !important;
    padding: 8px !important;
    box-shadow: none !important;
    width: 30vw !important;  
    height: fit-content !important;  /* Auto-adjust height to fit items */
    max-height: 100vh !important; /* Set max height to viewport height */
  }
  