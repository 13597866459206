.player-tag {
    padding: 8px 12px; /* Adjust padding for tighter fit */
    font-size: var(--body-text-size-large);
    font-family: var(--main-font);
    background-color: var(--background-medium-dark);
    border: 0px;
    border-radius: 8px;
    display: inline-flex; /* Make the tag fit its content */
    align-items: center; /* Vertically center the content */
    color: var(--title-text-light);
    max-width: fit-content; /* Prevents the tag from expanding to fill space */
}


.ant-tag-close-icon {
    color: var(--title-text-light) !important;
    font-size: 16px !important;
}