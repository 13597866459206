.svg.primary {
    background: none;
    border: 0px !important;
    margin: 2px !important;
    display: inline-block; /* Ensure it behaves properly inline */
    vertical-align: middle; /* Align properly if used with text or buttons */
  }
  
  .svg.primary path {
    stroke: currentColor; /* Use the desired color for the path */
    fill: none; /* If you want no fill for the SVG path */
  }
  
  .svg.primary:hover {
    background: none;
  }
  
  .svg.primary:hover path {
    stroke: var(--background-accent-dark); /* Invert stroke to dark color */
    fill: none;
  }

  