/* CenterPanel.css */

.center-panel {
      
      .tabs-background {
        border-radius: 8px 8px 0 0 !important;
        border-color: var(--background-accent-medium) !important;
        padding: 8px !important;
        border-bottom: 1px solid var(--background-accent-dark) !important;
      }
      
      .ant-tabs-nav-wrap {
        padding: 0px !important;
        margin: 0px !important;
      }
      
      .ant-tabs-nav{
        margin: 0px !important;
      }
      
      .ant-tabs-card .ant-tabs-tab {
        background-color: var(--background-medium-dark) !important; /* Background color */
        border: 2px solid transparent !important; /* Tab border color */
        padding: 0px 0px !important; /* Padding */
      }
      
      .ant-tabs-tab {
        background-color: var(--background-medium) !important; /* cardBg */
        padding: 4px 8px !important; /* cardPadding */
        margin-top: 8px !important;
        margin-right: 8px !important;
        margin-left: 0px !important;
        margin-bottom: 0px !important;
        border-radius: 8px !important;
        color: var(--title-text-light) !important; /* itemColor */
      }
      
            
      .public-highlight {
        border: 3px solid var(--background-accent-dark) !important;
        border-radius: 8px !important;
      }

      .ant-tabs-nav::before {
        border-bottom: none !important;
      }
      .ant-tabs-tab-active{
        
      }
      .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--title-text-light) !important; /* itemActiveColor */
        border-bottom: 2px solid var(--background-accent-light) !important; /* inkBarColor */
        
      }
      
      .ant-tabs-tab .ant-tabs-tab-btn {
        border-bottom: 2px solid var(--background-medium);
      }
      
      .ant-tabs-tab:hover .ant-tabs-tab-btn {
        color: var(--title-text-glowmode) !important; /* itemHoverColor */
        border-bottom: 2px solid var(--background-accent-dark) !important; /* Hover underline color */
      }    
     
      .ant-tabs-ink-bar {
        height: 0px !important;
      }
  
    .tabs-background {
        background-color: var(--background-medium-dark);
        padding: 4px 4px 16px 4px;
        border-radius: 8px;
        align-items: top !important;
        align-self: top !important;
    }
}