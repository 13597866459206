.iconified-title {
  align-items: center;
  gap: 10px;
}

.iconified-title-text  {
  margin-bottom: 0 !important;
}
.iconifiged-title-text{
  margin-bottom: 0 !important;
}


