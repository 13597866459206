/* src/components/css/LoginForm.css */

.login-row {
    height: 100vh;
}

.login-form {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.login-form .ant-form-item-label > label {
    font-weight: 600;
}

.login-button {
    margin-top: 16px;
}

.signup-link {
    text-align: center;
    margin-top: 16px;
}

.signup-link a {
    color: #1890ff;
    text-decoration: underline;
}
