/* Font */
.fab-main-font {
    font-family: var(--main-font) !important;
  }
  
  /* Body Text Colors */
  .fab-body-text-light {
    color: var(--body-text-light) !important;
  }
  
  .fab-body-text-medium {
    color: var(--body-text-medium) !important;
  }
  
  .fab-body-text-dark {
    color: var(--body-text-dark) !important;
  }
  
  .fab-body-text-accent {
    color: var(--body-text-accent) !important;
  }
  
  .fab-body-text-link {
    color: var(--body-text-link) !important;
  }
  
  .fab-body-text-extra-light {
    color: var(--body-text-extra-light) !important;
  }
  
  /* Body Text Sizes */
  .fab-body-text-size-large {
    font-size: var(--body-text-size-large) !important;
  }
  
  .fab-body-text-size-medium {
    font-size: var(--body-text-size-medium) !important;
  }
  
  .fab-body-text-size-small {
    font-size: var(--body-text-size-small) !important;
  }
  
  /* Title Text Colors */
  .fab-title-text-light {
    color: var(--title-text-light) !important;
  }
  
  .fab-title-text-glowmode {
    color: var(--title-text-glowmode) !important;
  }
  
  .fab-title-text-extralight {
    color: var(--title-text-extralight) !important;
  }
  
  .fab-title-text-dark {
    color: var(--title-text-dark) !important;
  }
  
  /* Background Colors */
  .fab-background-dark {
    background-color: var(--background-dark) !important;
  }
  
  .fab-background-medium-dark {
    background-color: var(--background-medium-dark) !important;
  }
  
  .fab-background-medium {
    background-color: var(--background-medium) !important;
  }
  
  .fab-background-medium-light {
    background-color: var(--background-medium-light) !important;
  }
  
  .fab-background-light {
    background-color: var(--background-light) !important;
  }
  
  .fab-background-very-light-2 {
    background-color: var(--background-very-light-2) !important;
  }
  
  .fab-background-very-light {
    background-color: var(--background-very-light) !important;
  }
  
  /* Background Accent Colors */
  .fab-background-accent-light {
    background-color: var(--background-accent-light) !important;
  }
  
  .fab-background-accent-medium {
    background-color: var(--background-accent-medium) !important;
  }
  
  .fab-background-accent-dark {
    background-color: var(--background-accent-dark) !important;
  }
  
  /* Border Colors */
  .fab-border-color-dark {
    border-color: var(--border-color-dark) !important;
  }
  
  .fab-border-color-medium {
    border-color: var(--border-color-medium) !important;
  }
  
  .fab-border-color-light {
    border-color: var(--border-color-light) !important;
  }
  
  /* Gradient Backgrounds */
  .fab-gradient-background-1 {
    background: var(--gradient-background-1) !important;
  }
  
  .fab-gradient-background-2 {
    background: var(--gradient-background-2) !important;
  }
  
  .fab-gradient-background-before {
    background: var(--gradient-background-before) !important;
  }
  
  /* Button Primary */
  .fab-button-primary-background {
    background-color: var(--button-primary-background) !important;
  }
  
  .fab-button-primary-color {
    color: var(--button-primary-color) !important;
  }
  
  .fab-button-primary-border {
    border-width: var(--button-primary-border) !important;
    border-color: var(--button-primary-border-color) !important;
  }
  
  .fab-button-primary-hover-color {
    color: var(--button-primary-hover-color) !important;
  }
  
  .fab-button-primary-background-disabled {
    background-color: var(--button-primary-background-disabled) !important;
  }
  
  .fab-button-primary-color-disabled {
    color: var(--button-primary-color-disabled) !important;
  }
  
  .fab-button-primary-border-color-disabled {
    border-color: var(--button-primary-border-color-disabled) !important;
  }
  
  /* Button Secondary */
  .fab-button-secondary-background {
    background-color: var(--button-secondary-background) !important;
  }
  
  .fab-button-secondary-color {
    color: var(--button-secondary-color) !important;
  }
  
  .fab-button-secondary-border {
    border-width: var(--button-secondary-border) !important;
    border-color: var(--button-secondary-border-color) !important;
  }
  
  .fab-button-secondary-background-disabled {
    background-color: var(--button-secondary-background-disabled) !important;
  }
  
  .fab-button-secondary-color-disabled {
    color: var(--button-secondary-color-disabled) !important;
  }
  
  .fab-button-secondary-border-color-disabled {
    border-color: var(--button-secondary-border-color-disabled) !important;
  }
  
  /* Text Disabled Color */
  .fab-text-disabled-color {
    color: var(--text-disabled-color) !important;
  }
  
  /* Header Height */
  .fab-header-height {
    height: var(--header-height) !important;
  }
  