/* styles.css */
.markdown-editor {
    font-family: var(--main-font);
    color: var(--body-text-medium);
    background-color: var(--background-very-light);
    padding: 20px;
    
  }
 
  .markdown-editor div,
  .markdown-editor h1,
  .markdown-editor h2,
  .markdown-editor h3,
  .markdown-editor h4,
  .markdown-editor h5{
    color: var(--title-text-dark);
    text-decoration-color: var(--title-text-medium);
    background-color: var(--background-very-light);
    margin: 10px 0 10px;
    padding: 0;
  }
  
  .markdown-editor p {
    color: var(--body-text-dark);
    background-color: var(--background-very-light);
    margin: 10px 0;
    padding: 0;
  }
  
  .markdown-editor a {
    color: var(--body-text-link);
    text-decoration: underline;
    background-color: var(--background-very-light);
  }
  
  .markdown-editor code {
    background-color: var(--background-medium-light);
    color: var(--body-text-dark);
    padding: 2px 4px;
    border-radius: 3px;
  }
  
  .markdown-editor blockquote {
    border-left: 4px solid var(--background-accent-medium);
    padding-left: 16px;
    color: var(--body-text-accent);
    background-color: var(--background-very-light);
    margin: 10px 0;
    padding: 10px 0 10px 16px;
  }
  
  .markdown-editor ul,
  .markdown-editor ol {
    color: var(--body-text-dark);
    margin-left: 20px;
    background-color: var(--background-very-light);
    margin: 10px 0;
    padding: 0 0 0 20px;
  }
  
  .markdown-editor li {
    margin: 5px 0;
    background-color: var(--background-very-light);
  }
  
  .markdown-editor hr {
    border: 1px solid var(--background-accent-medium);
    margin: 20px 0;
    background-color: var(--background-very-light);
  }
  
  .markdown-editor table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--background-very-light);
  }
  
  .markdown-editor th,
  .markdown-editor td {
    border: 1px solid var(--background-accent-medium);
    padding: 8px;
    text-align: left;
    background-color: var(--background-very-light);
  }
  
  