.custom-markdown {
    font-family: var(--main-font);
    color: var(--body-text-dark);
    background-color: var(--background-very-light);
    border-radius: 0px 0px 8px 8px !important;
    line-height: 1.6;
    font-size: var(--body-text-size-large);
    padding: 1rem;
  }
  
  .custom-markdown h1,
  .custom-markdown h2,
  .custom-markdown h3,
  .custom-markdown h4,
  .custom-markdown h5,
  .custom-markdown h6 {
    color: var(--body-text-dark);
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .custom-markdown h1 {
    font-size: 2em;
  }
  
  .custom-markdown h2 {
    font-size: 1.75em;
  }
  
  .custom-markdown h3 {
    font-size: 1.5em;
  }
  
  .custom-markdown p {
    color: var(--body-text-dark);
    margin-bottom: 1rem;
  }
  
  .custom-markdown a {
    color: var(--body-text-link);
    text-decoration: none;
  }
  
  .custom-markdown a:hover {
    text-decoration: underline;
  }
  
  .custom-markdown blockquote {
    border-left: 4px solid var(--background-accent-medium);
    padding-left: 1rem;
    margin-left: 0;
    color: var(--body-text-extra-light);
    background-color: var(--background-light);
  }
  
  .custom-markdown code {
    background-color: var(--background-medium-dark);
    color: var(--body-text-accent);
    padding: 0.2em 0.4em;
    border-radius: 4px;
    font-size: var(--body-text-size-medium);
  }
  
  .custom-markdown pre {
    background-color: var(--background-dark);
    color: var(--body-text-light);
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    font-size: var(--body-text-size-small);
  }
  
  .custom-markdown ul,
  .custom-markdown ol {
    color: var(--body-text-medium);
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .custom-markdown ul li,
  .custom-markdown ol li {
    margin-bottom: 0.5rem;
  }
  
  .custom-markdown hr {
    border: none;
    border-top: 2px solid var(--border-color-light);
    margin: 2rem 0;
  }
  
  .custom-markdown table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  
  .custom-markdown th,
  .custom-markdown td {
    padding: 0.5rem;
    border: 1px solid var(--border-color-medium);
  }
  
  .custom-markdown th {
    background-color: var(--background-accent-light);
    color: var(--title-text-dark);
  }
  
  .custom-markdown td {
    background-color: var(--background-light);
    color: var(--body-text-light);
  }
  