.icon-display {
    display: flex;
    justify-content: center;
    align-items: top;
    border-radius: 8px;
    border: 2px;
    border-color: var(--background-dark);
  }
  
  .icon-container {
 
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    border-radius: 8px;
    border: 2px;
    border-color: var(--background-dark);
  }