/* SiteHeader.css */
.site-header-row {
  padding: 16px 0;
  background-color: var(--background-dark);
  min-width: 100%;
  display: flex; /* Use flexbox to make it responsive to its children */
  align-items: stretch; /* Align children items to the same height */
}

.logo-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px; /* Adds 16px padding to the left */
  width: auto;
}

.input-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px; /* Adds 16px padding to the right */
  padding-top: 0px !important; /* Removes default padding */
  margin-top: 0px !important; /* Removes default margin */
  width: auto;
  gap: 8px; /* Adds space between the inputs */
}




