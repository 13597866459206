.foundry-card-item {
    background-color: var(--background-medium);
    border-radius: 4px;
    border-color: var(--background-dark);
    padding: 0px !important;
    margin: 0px 4px 2px 4px !important; /* Add spacing between list items */
    position: relative; /* For positioning the icon */
  }
  .foundry-card-header {
    color: var(--body-text-light) !important;
    display: flex;
    align-items: center;
    margin: 0px !important;
  }
  
  .ant-card-head {
    padding: 0px 8px 0px  !important;
    margin: 0px !important;
    min-height: 0px !important;
  }
  
  .foundry-card-item .ant-card-body {
    padding: 4px !important;
  }
  
  .foundry-card-content {
    display: flex;
    flex-direction: column;
  }
  
  .foundry-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    margin-left: 4px;
  }
  
  .foundry-card-label {
    font-size: var(--body-text-size-medium);
    color: var(--body-text-light);
  }
  
  .foundry-card-icon {
    color: var(--body-text-light);
  }
  
  .ant-input.custom-input {
    font-size: var(--body-text-size-large) !important;
    background-color: var(--background-light) !important;
    border: 1px solid var(--background-medium) !important; /* Corrected border property */
    color: var(--body-text-dark) !important;
    width: 99%; /* Changed to 100% for consistency */
    margin: .5%;
  }
  
  .ant-input.custom-input::placeholder,
  .ant-input-password.custom-input::placeholder {
    color: var(--body-text-medium);
    text-align: left;
  }
  
  .ant-input.custom-input:focus,
  .ant-input.custom-input:hover {
    background-color: var(--background-very-light-2) !important;
    border: 2px solid var(--background-dark) !important; /* Corrected border property */
    color: var(--body-text-medium);
  }
  
  .foundry-card-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .foundry-card-avatar img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  
  /* Style for the second column container */
.foundry-tab-second-column {
  background-color: var(--background-medium-dark); /* Use medium dark background for contrast */
  border-radius: 8px; /* Rounded corners for a polished look */
  padding: 16px; /* Add padding for better content spacing */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  border: 1px solid var(--border-color-medium); /* Border to separate it visually */
  margin-left: 16px; /* Add some margin between the two columns */
}

/* Style for the title in the second column */
.foundry-tab-second-column-title {
  color: var(--body-text-light); /* Title text color */
  font-size: var(--body-text-size-large); /* Make the title font size large */
  margin-bottom: 12px; /* Add margin below the title */
}

/* Style for the InputNumber (number spinner) */
.degree-spinner,
.degree-spinner:focus {
  background-color: var(--background-light); /* Light background */
  border: 1px solid var(--background-medium); /* Border matching the rest of the app */
  color: var(--body-text-dark); /* Dark text color for readability */
  border-radius: 4px; /* Slightly rounded corners */
  padding: 4px; /* Add padding for a larger clickable area */
  margin-bottom: 12px; /* Space below the spinner */
  width: 66px; /* Make the spinner take the full width */
}
.ant-input-number-outlined {
  background-color: var(--background-light) !important; /* Light background */
}
.ant-input-number-input:focus {
  background-color: var(--background-light); /* Light background */
  color: var(--body-text-dark); /* Dark text color for readability */
}
.ant-input-number:hover {
  background-color: var(--background-light); /* Light background */
  border-color: var(--background-medium-dark); /* Add hover effect if needed */
}

.ant-input-number-handler {
  background-color: var(--background-light); /* Add hover effect if needed */
  color: var(--body-text-light) !important;
}
/* Style for the linked objects list */
.linked-objects-list {
  background-color: var(--background-medium); /* Light background */
  border: none;
  color: var(--body-text-light) !important; /* Medium color for list items */
  border-radius: 4px; /* Rounded corners for list */
  padding: 8px; /* Add padding to the list */
}

/* Style for individual list items */
.linked-objects-list-item {
  background-color: var(--background-medium); 
  color: var(--body-text-light) !important; /* Medium color for list items */
  padding: 8px 4px; /* Add padding around the list items */
  border-bottom: 1px solid var(--border-color-light); /* Add a light border between items */
}

/* Last list item without border */
.linked-objects-list-item:last-child {
  border-bottom: none; /* Remove the border for the last item */
}
