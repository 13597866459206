/* NotAuthorized.css */

.not-authorized-row {
    min-height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-dark); /* Use your defined background color */
  }
  
  .frame {
    padding: 24px;
    border: 2px solid var(--border-color-medium); /* Frame border color */
    border-radius: 12px;
    background-color: var(--background-medium-dark); /* Frame background color */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .custom-title {
    margin-bottom: 16px;
  }
  
  .user-actions {
    margin-top: 24px;
  }
  
  .text-primary {
    font-size: 16px;
    color: var(--body-text-medium);
  }
  
  .text-orange {
    color: var(--title-text-light);
    font-weight: bold;
  }
  
  .large-size {
    font-size: 18px;
  }
  
    .user-actions {
    margin-top: 24px;
  }
  