.ant-switch {
    background-color: var(--background-accent-dark) !important; /* Align with primary button background */
    border: 1px solid var(--background-accent-medium) !important; /* Align with button border */
    height: 24px !important;
    width: 68px !important;
    margin: 0px !important;
    border-radius: 8px;
}

.ant-switch::before {
    background-color: var(--background-accent-dark) !important; /* Align with primary button background */
}

.ant-switch-checked {
    background-color: var(--title-text-light) !important; /* Align with primary button hover background */
    color: var(--background-accent-dark) !important; /* Align with primary button hover text */
}

.ant-switch-checked::before {
    background-color: var(--title-text-light) !important; /* Align with primary button hover background */
}

.ant-switch-inner {
    color: var(--title-text-light) !important; /* Align with primary button text */
    font-family: var(--main-font);
    font-size: var(--body-text-size-medium);
    text-align: center;
}

.ant-switch-small {
    height: 20px !important;
    width: 80px !important;
    border-radius: 8px;
}

.ant-switch-small::before {
    height: 25px;
    width: 60px;
}

.ant-switch:hover {
    background-color: var(--title-text-light) !important; /* Align with primary button hover background */
    color: var(--background-accent-dark) !important; /* Align with primary button hover text */
}

.ant-switch-checked:hover {
    background-color: var(--background-accent-dark) !important; /* Align with primary button background */
    color: var(--title-text-light) !important; /* Align with primary button text */
}

.ant-switch-disabled {
    background-color: var(--button-primary-background-disabled) !important; /* Align with primary button disabled background */
    border-color: var(--button-primary-border-color-disabled) !important; /* Align with primary button disabled border */
}

.ant-switch-disabled .ant-switch-inner {
    color: var(--button-primary-color-disabled) !important; /* Align with primary button disabled text */
}

.ant-switch-disabled::before {
    background-color: var(--button-primary-background-disabled) !important; /* Align with primary button disabled background */
}
