/* css/InputField.css */
.input-margin-bottom {
  margin-bottom: 8px; /* Add margin at the bottom to create space between inputs */
}

.ant-btn {
  background-color: var(--background-accent-dark);
  color: var(--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.ant-btn.right{
  border-radius: 0px 8px 8px 0px;
}

.ant-btn.right:hover,
.ant-btn:hover {
  background-color: var(--title-text-light) !important;
  color: var(--background-accent-dark) !important;
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ant-btn.left {
  background-color: var(--background-accent-dark);
  color: var(--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.ant-btn.left:hover {
  background-color: var(--title-text-light) !important;
  color: var(--background-accent-dark) !important;
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ant-space-item .custom-title-select{
  width: 100%;
  border-radius: 8px !important;
  /* Background and text color inside the input field */
  padding: 16px !important;
  font-size: 32px !important;
  border: none; /* Remove default border */
  outline: none; /* Remove default outline on focus */
  min-width: 200px !important; 
}

.ant-select .campaign-select {
  width: 100%;
  border-radius: 8px !important;
  /* Background and text color inside the input field */
  padding: 8px !important;
  font-size: 16px !important;
  border: none; /* Remove default border */
  outline: none; /* Remove default outline on focus */
  min-width: 200px !important; 
}

.ant-select-item {
  font-size: 18px !important;
  color: var(--title-text-dark) !important;
}

.ant-select-dropdown {
  background-color: var(--background-light) !important; 
  border: none !important;
  padding: 8px !important;
  box-shadow: none !important;
  width: 30vw !important;  
  height: fit-content !important;  /* Auto-adjust height to fit items */
  max-height: 100vh !important; /* Set max height to viewport height */
}

/* CSS file or style block */
.element-margined-nill .ant-space-item {
  height: 40px; /* Set the desired height */
  line-height: 40px; /* Adjust line height to center content */
}

.small-select .ant-select-dropdown{
  background-color: var(--background-light) !important; 
  border: none !important;
  padding: 8px !important;
  box-shadow: none !important;
  width: 30vw !important;  
  height: fit-content !important;  /* Auto-adjust height to fit items */
  max-height: 100vh !important; /* Set max height to viewport height */
}

.campaign-select .ant-select-dropdown{
  background-color: var(--background-light) !important; 
  border: none !important;
  padding: 8px !important;
  box-shadow: none !important;
  width: 40vw !important;  
  height: fit-content !important;  /* Auto-adjust height to fit items */
  max-height: 100vh !important; /* Set max height to viewport height */
}

.ant-select-item-option-content
{
  color: var(--title-text-dark) !important;
}

.small-select .ant-select-item-option-content
{
  color: var(--body-text-dark) !important;
  font-size: 16px !important;
}

.ant-select-item-option-active {
  background-color: var(--background-medium) !important;
  color: var(--title-text-dark) !important;
}

.ant-select-item-option-selected {
  background-color: var(--background-very-light) !important;
  color: var(--title-text-light) !important;
}

.ant-select-selector 
{
  font-size: 32px  !important;
  border: none !important;
  box-shadow: none !important;
}

.campaign-select .ant-select-selector 
{
  font-size: 16px  !important;
  border: none !important;
  box-shadow: none !important;
}


.ant-select-selection-search
.ant-select-selector
{
  color: var(--title-text-light) !important;
}

.campaign-select .ant-select-selection-item {
  font-size: 16px  !important;
  color: var(--body-text-dark) !important;
}

.ant-select-selection-item {
  font-size: 32px  !important;
  color: var(--title-text-light) !important;
}

.small-select .ant-select-selection-item {
  font-size: 16px !important;
  color: var(--body-text-dark) !important;
}

.custom-title-select {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  font-family: var(--main-font) !important;
  color: var(--title-text-dark) !important;
  font-style: bold !important ;
}


.ant-select-selection-item-title:where(.ant-select-single.ant-select-open) {
  border: none !important;
  color: var(--title-text-dark) !important;
}

.ant-select-item-option-active {
  background-color: var(--background-medium-light) !important;
  color: var(--title-text-light) !important;
}


.ant-input.custom-title-input {
  width: 100%;
  border-radius: 8px !important;
  /* Background and text color inside the input field */
  background: #5B7882;
  color: #BFE5D3;
  border: none; /* Remove default border */
  outline: none; /* Remove default outline on focus */
  min-width: 200px !important;
}

.user-identity-credential-input {
  width: 100%;
}


/* Typography */
.custom-input-title h1.ant-typography,
.custom-input-title h2.ant-typography,
.custom-input-title h3.ant-typography,
.custom-input-title h4.ant-typography,
.custom-input-title h5.ant-typography {
  font-family: var(--main-font);
  color: var(--title-text-light) !important;
}

.custom-input-title-2 h1.ant-typography,
.custom-input-title-2 h2.ant-typography,
.custom-input-title-2 h3.ant-typography,
.custom-input-title-2 h4.ant-typography,
.custom-input-title-2 h5.ant-typography {
  color: var(--body-text-dark) !important;
  font-family: var(--main-font);
}

.custom-input-title,
.custom-input-title h1.ant-typography,
.custom-input-title h2.ant-typography,
.custom-input-title h3.ant-typography,
.custom-input-title h4.ant-typography,
.custom-input-title h5.ant-typography {
  font-family: var(--main-font);
  color: var(--title-text-light) !important;
}

.realm-input-container,
.realm-input-container .ant-space-item
{
  margin-right: 16px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}

.header-title {
  font-family: var(--main-font);
  color: var(--title-text-light) !important;
  align-self: middle !important;
  margin: 0px !important;
}