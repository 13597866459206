/* Assuming this is in attributeCard.css */

.attribute-card-item {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid var(--border-color-light) !important;
    background: transparent;
  }
  
  
 
  

  
 
  