/* styles.css */
.story-presenter
{
  height: 100%;
}

.markdown-previewer {
    font-family: var(--main-font);
    color: var(--body-text-medium);
    background-color: var(--background-very-light);
    padding: 20px;
    min-height: 100px;
  }
 
  .markdown-previewer div,
  .markdown-previewer h1,
  .markdown-previewer h2,
  .markdown-previewer h3,
  .markdown-previewer h4,
  .markdown-previewer h5{
    color: var(--title-text-dark);
    text-decoration-color: var(--title-text-medium);
    background-color: var(--background-very-light);
    margin: 10px 0 10px;
    padding: 0;
  }
  
  .markdown-previewer p {
    color: var(--body-text-dark);
    background-color: var(--background-very-light);
    margin: 10px 0;
    padding: 0;
  }
  
  .markdown-previewer a {
    color: var(--body-text-link);
    text-decoration: underline;
    background-color: var(--background-very-light);
  }
  
  .markdown-previewer code {
    background-color: var(--background-medium-light);
    color: var(--body-text-dark);
    padding: 2px 4px;
    border-radius: 3px;
  }
  
  .markdown-previewer blockquote {
    border-left: 4px solid var(--background-accent-medium);
    padding-left: 16px;
    color: var(--body-text-accent);
    background-color: var(--background-very-light);
    margin: 10px 0;
    padding: 10px 0 10px 16px;
  }
  
  .markdown-previewer ul,
  .markdown-previewer ol {
    color: var(--body-text-dark);
    margin-left: 20px;
    background-color: var(--background-very-light);
    margin: 10px 0;
    padding: 0 0 0 20px;
  }
  
  .markdown-previewer li {
    margin: 5px 0;
    background-color: var(--background-very-light);
  }
  
  .markdown-previewer hr {
    border: 1px solid var(--background-accent-medium);
    margin: 20px 0;
    background-color: var(--background-very-light);
  }
  
  .markdown-previewer table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: var(--background-very-light);
  }
  
  .markdown-previewer th,
  .markdown-previewer td {
    border: 1px solid var(--background-accent-medium);
    padding: 8px;
    text-align: left;
    background-color: var(--background-very-light);
  }
  
  /* Align the toolbar to the left */
.mde-header { 
  justify-content: flex-start !important;
}

/* Optional: Add padding or margin if needed */
.custom-editor .mdxeditor
{
  padding-left: 0; /* Remove any left padding */
  margin-left: 0; /* Remove any left margin */
  font-family: var(--main-font) !important;
  font-size: var(--body-text-size-medium) !important;
  height: 100% !important;
}

.custom-editor .mdxeditor-toolbar
{
  background-color: var(--background-very-light);
  color: var(--body-text-dark);
  border-radius: 8px 8px 0px 0px !important;
}

.mdxeditor-root-contenteditable
{
  height: 100% !important;
  border-radius: 8px !important;
  
}

.small-story {
  min-height: 100px !important;
  /* Add any other styles specific to small stories */
}

.medium-story,
.medium-story-editor {
  min-height: 400px !important;
  /* Add any other styles specific to medium stories */
}

.custom-editor.large-story {
  min-height: 200px !important;
  /* Add any other styles specific to large stories */
}

.edit-button {
  position: absolute;
  top: 0px;
  right: 4px;
  z-index: 100;
}